(function($){
    $.fn.serializeObject = function () {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function () {
            if (o[this.name]) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    }

    $(document).ready(function(){

        var fromDay = $('.arriving-tab .date');
        var fromMonth = $('.arriving-tab .month');
        var fromYear = $('.arriving-tab .year');

        var toDay = $('.departing-tab .date');
        var toMonth = $('.departing-tab .month');
        var toYear = $('.departing-tab .year');

        var checkin = $('.arriving-tab .dp');
        var checkout = $('.departing-tab .dp');
        var submit_btn = $('#bf_submit');
        var cancel_btn = $('.quick-search-cancel');

        var current_connect_name = hotel_data.hotel_connect_name;
        var nbdelay = hotel_data.hotel_nbdelay;
        if(nbdelay === ''){
            nbdelay = 0;
        }
        nbdelay = parseInt(nbdelay);

        function init_default_values(){
            //update checkin dates
            var thisday = new Date();
            fromDay.text($.datepicker.formatDate('dd/mm/yy', thisday));

            //update checkout dates
            thisday.setDate(thisday.getDate()+1);
            toDay.text($.datepicker.formatDate('dd/mm/yy', thisday));
        }

        function init_bookingform(){

            // On click show datepicker checkin
            $('#booking_form .arriving-tab').click(function(){
                $(this).find('.calendar').css('display', 'block');
                $('#booking_form .departing-tab .calendar').css('display', 'none');
            });

            // On click show datepicker checkout
            $('#booking_form .departing-tab').click(function(){
                $(this).find('.calendar').css('display', 'block');
                $('#booking_form .arriving-tab .calendar').css('display', 'none');
            });

            var mindate = new Date();
            var mindate2;
            mindate.setDate(mindate.getDate() + nbdelay);

            checkin.datepicker({
                'minDate':mindate,
                onSelect:function(date){
                    mindate2 = checkin.datepicker('getDate');
                    mindate2.setTime(mindate2.getTime() + 86400000);
                    checkout.datepicker('setDate', mindate2);
                    checkout.datepicker('option', 'minDate', mindate2);
                    $('#booking_form .arriving-tab .calendar').css('display', 'none');
                    $('#booking_form .departing-tab .calendar').css('display', 'block');
                    $('.arriving-tab').val(checkin.datepicker('getDate'));
                    update_checkin_inputs();
                    update_checkin_dates();
                }
            });

            checkout.datepicker({
                defaultDate: +1,
                'minDate':mindate,
                onSelect: function () {
                    $('#booking_form .departing-tab .calendar').css('display', 'none');
                    $('.departing-tab').val(checkout.datepicker('getDate'));
                    update_checkout_inputs();
                    update_checkout_dates();
                }
            });


            $(document).mouseup(function(e){
                var container = $('quickbook-form-tab');
                if(!container.is(e.target) && container.has(e.target).length === 0){
                    $('#booking_form .arriving-tab .calendar').css('display', 'none');
                    $('#booking_form .departing-tab .calendar').css('display', 'none');
                }
            });


            //Adults
            $('select[name=adulteresa]').on('change', function(){
                $('input[name=adulteresa]').val($('select[name=adulteresa]').val());
            });

            // Children
            $('select[name=enfantresa]').on('change', function(){
                $('input[name=enfantresa]').val($('select[name=enfantresa]').val());
            });
        }

        function update_checkin_inputs(){
            // update checkin input
            var checkinDate = checkin.datepicker('getDate');
            $('input[name=fromday]').val(checkinDate.getDate());
            $('input[name=frommonth]').val(checkinDate.getMonth()+1);
            $('input[name=fromyear]').val(checkinDate.getFullYear());
        }

        function update_checkout_inputs(){
            // update checkout input
            var checkoutDate = checkout.datepicker('getDate');
            $('input[name=today]').val(checkoutDate.getDate());
            $('input[name=tomonth]').val(checkoutDate.getMonth()+1);
            $('input[name=toyear]').val(checkoutDate.getFullYear());
        }

        function update_checkin_dates(){
            var checkinDate = checkin.datepicker('getDate');

            //update checkin dates
            fromDay.text($.datepicker.formatDate('dd/mm/yy', checkinDate));
        }

        function update_checkout_dates(){
            var checkoutDate = checkout.datepicker('getDate');

            // update checkout dates
            toDay.text($.datepicker.formatDate('dd/mm/yy', checkoutDate));
        }

        //Start
        if(typeof hotel_data.hotel_connect_name !== 'undefined'){
            init_default_values();
            init_bookingform();
        }

        //Select Hotel option
        var value,
        hotel_cname;
        $(".hotels-select").on("change", function () {
            hotel_cname = $(".hotels-select option:selected").attr('data-hotel-cname');
            value = $(this).val();
            $("#idForm:first input[name=Clusternames]").val(hotel_cname);
            $("#idForm:first input[name=Hotelnames]").val(value);
        });

        $("#bf_submit").on("click", function(e) {
            e.preventDefault();
            hhotelDispoprice(document.idForm);
        });

        $(".hotels-select").trigger("change");

    });

})(jQuery);